import { Link } from "react-router-dom";

import logo from "../images/logo.webp"

const Dashboard = () => {
    return (
        <>
            <h1>Feuerwehr TWW – Memory</h1>
            <div className="container--small">
                <img className="logo" src={logo} width={400} alt="Feuerwehr Turbenthal Wila Wildberg" />
            </div>
            <div className="container--small">
                <Link to="/stapel1" className="button-primary">
                    Stapel1
                </Link>
                <Link to="/stapel2" className="button-primary">
                    Stapel2
                </Link>
            </div>
        </>
    );
};

export default Dashboard;
