import { useState, useRef } from "react";
import Card from "../Components/Card";
import jsonData from "../data/data1.json";
import { Link } from "react-router-dom";

const Stapel1 = () => {
    jsonData.sort(() => Math.random() - 0.5);
    const [cards, setCards] = useState(jsonData);

    const [previousCardState, setPreviousCardState] = useState(-1);
    const previousIndex = useRef(-1);

    const matchCheck = (currentCard) => {
        if (cards[currentCard].id === cards[previousCardState].id) {
            cards[previousCardState].status = "active matched";
            cards[currentCard].status = "active matched";
            setPreviousCardState(-1);
        } else {
            cards[currentCard].status = "active";
            setCards([...cards]);
            setTimeout(() => {
                setPreviousCardState(-1);
                cards[currentCard].status = "unmatch";
                cards[previousCardState].status = "unmatch";
                setCards([...cards]);
            }, 1000);
        }
    };

    const clickhandler = (index) => {
        if (index !== previousIndex.current) {
            if (cards[index].status === "active matched") {
                alert("already matched");
            } else {
                if (previousCardState === -1) {
                    previousIndex.current = index;
                    cards[index].status = "active";
                    setCards([...cards]);
                    setPreviousCardState(index);
                } else {
                    matchCheck(index);
                    previousIndex.current = -1;
                }
            }
        } else {
            alert("card currently seleted");
        }
    };

    return (
        <>
            <h1>Feuerwehr TWW – Memory Stapel 1</h1>
            <div className="container">
                {cards.map((card, index) => {
                    return (
                        <Card
                            key={index}
                            card={card}
                            index={index}
                            clickhandler={clickhandler}
                        />
                    );
                })}
            </div>
            <div className="footer">
                <Link to="/" className="button-primary">
                    Zurück
                </Link>
            </div>
        </>
    );
};

export default Stapel1;
