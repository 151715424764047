import { Routes, Route } from "react-router-dom";

import "./App.css";

import Dashboard from "./pages/Dashboard";
import Stapel1 from "./pages/Stapel1";
import Stapel2 from "./pages/Stapel2";

function App() {
    return (
        <Routes>
            <Route index element={<Dashboard />} />
            <Route path="/stapel1" element={<Stapel1 />} />
            <Route path="/stapel2" element={<Stapel2 />} />
        </Routes>
    );
}

export default App;
